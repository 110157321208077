import React, { useRef, useEffect } from 'react';
import { shape, string, func } from 'prop-types';
import { Image as ImageComponent } from '@thd-olt-component-react/core-ui';

import './customer-image-overlay.scss';

const MainImage = (props) => {
  const {
    getNextImage,
    getPreviousImage,
    interactionHandler,
    interactionTypes,
    photoUrl
  } = props;
  const { SWIPE_NEXT, SWIPE_PREVIOUS } = interactionTypes;
  let HammerTime = null;

  const myRef = useRef();

  const handleSwipe = (event) => {
    const { direction } = event;
    if (direction === 2) {
      getNextImage();
      interactionHandler(SWIPE_NEXT);
    } else if (direction === 4) {
      getPreviousImage();
      interactionHandler(SWIPE_PREVIOUS);
    }
  };

  const enableSwipe = async () => {
    if (myRef?.current !== null) {
      const Hammer = await import(/* webpackChunkName: "hammerjs" */ 'hammerjs');
      // eslint-disable-next-line new-cap
      HammerTime = new Hammer.default(myRef.current);
      HammerTime?.on('swipe', handleSwipe);
    }
  };

  useEffect(() => {
    enableSwipe();
    return () => { HammerTime?.off('swipe', handleSwipe); };
  }, []);

  return (
    <div className="center" ref={myRef} data-testid="main-image">
      <ImageComponent className="image-alignment" src={photoUrl} alt="" />
    </div>
  );
};

MainImage.propTypes = {
  photoUrl: string,
  getNextImage: func.isRequired,
  getPreviousImage: func.isRequired,
  interactionHandler: func.isRequired,
  interactionTypes: shape({
    SWIPE_NEXT: string,
    SWIPE_PREVIOUS: string,
  }).isRequired,
};

MainImage.defaultProps = {
  photoUrl: '',
};
export { MainImage };
