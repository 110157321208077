import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './Rating.styles.scss';

/** THD Rating component. */
class Rating extends Component {

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.value,
    hover: null
  };

  componentDidUpdate(previousProps) {
    if (previousProps.value !== this.props.value && !isNaN(this.props.value)) {//eslint-disable-line
      this.refreshInputRatingValue(this.props);
    }
  }

  onRatingClick = (providedFunction, toggle, newValue) => {
    const { stayFilled } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const newStateValue = toggle && newValue === this.state.value ? null : newValue;
    if (newStateValue && stayFilled) {
      this.setState(() => {
        return { value: newStateValue };
      });
    }
    if (providedFunction) {
      providedFunction(newStateValue);
    }
  };

  onRatingHover = (event, providedFunction, interactive, hoveredValue) => {
    // If the rating is static, hover shouldn't change anything
    if (!interactive) {
      return;
    }

    this.setState(() => {
      if (providedFunction) {
        providedFunction(hoveredValue);
      }
      return { hover: hoveredValue };
    });
  };

  onRatingKeyPress = (event, providedFunction, toggle, newValue) => {
    // eslint-disable-next-line react/destructuring-assignment
    const newStateValue = toggle && newValue === this.state.value ? null : newValue;
    if (providedFunction && [13, 32].indexOf(event.keyCode) > -1) {
      this.setState({ value: newStateValue });
      providedFunction();
    }
  };

  refreshInputRatingValue = ({ value }) => {
    const { onHover } = this.props;
    if (onHover) {
      onHover(value);
    }
    this.setState({ value, hover: value });
  };

  staticStars = (ratingValue, additionalClasses, isRatingTextEnabled) => {
    const classes = classNames(
      additionalClasses,
      'ratings',
      'reviews',
      'grey',
      'reviews--no-bottom'
    );
    const ratingPercentage = (parseFloat(ratingValue) / 5) * 100;
    const ratingText = `${parseFloat(ratingValue).toFixed(1)} out of 5 stars`;
    return (
      <div
        className={classes}
        title={isRatingTextEnabled ? ratingText : ''}
      >
        <span
          className="stars"
          style={{ width: `${ratingPercentage}%` }}
        />
      </div>
    );
  };

  clearHoverState = (event, providedFunction) => {
    const starHovered = event.relatedTarget.className === 'mw-icon-star-symbol';
    const { interactive } = this.props;
    if (starHovered || !interactive) {
      return;
    }
    this.setState((prevState) => {
      if (providedFunction) {
        providedFunction(prevState.value);
      }
      return { hover: prevState.value };
    });

  };

  render() {
    const {
      className,
      interactive,
      onClick,
      onHover,
      orange,
      sectionClassName,
      titleEnabled,
      toggle,
      type
    } = this.props;
    const { hover, value } = this.state;

    const circleOrMeter = ['circle', 'meter'].indexOf(type) > -1;
    const stars = type === 'stars';
    const dash = type === 'dash';
    /* Use Global CSS for static stars */
    if (!interactive && stars) {
      return (this.staticStars(value, className, titleEnabled));
    }

    /* Based on the type - set the width of each section */
    let width = 'auto';
    if (circleOrMeter) {
      width = '50px';
    } else if (!stars) {
      width = '20%';
    }

    const sections = new Array(5).fill().map((val, index) => index + 1);
    const classes = classNames(className, 'rating');

    return (
      <div className={classes}>
        {
          sections.map((section, sectionIndex) => {
            const sectionStyle = { width };
            const fillValue = hover || Math.floor(value);
            const asset = orange && fillValue > sectionIndex ? 'star-symbol--orange.svg' : 'star-symbol--grey.svg';
            const fillClass = classNames(sectionClassName, {
              [`rating-scale__${type}__orange`]: orange && fillValue > sectionIndex,
              [`rating-scale__${type}__filled`]: fillValue > sectionIndex && !orange
            });
            const additionalPercentage = (value % 1) * 100;
            /** selectionIndex is 0 based */
            const lastSection = additionalPercentage > 0 && fillValue === sectionIndex;
            if (lastSection && (circleOrMeter || dash)) {
              // eslint-disable-next-line max-len
              sectionStyle.backgroundImage = `linear-gradient(to right, #f96302 ${additionalPercentage}%, #d3d3d3 ${additionalPercentage}%, #d3d3d3 ${100 - additionalPercentage}%)`;
            }
            const ratingClasses = classNames(
              'rating-scale',
              `rating-scale__${type}`,
              fillClass, {
                'rating-scale-interactive': interactive
              }
            );

            return (
              <span
                key={sectionIndex} // eslint-disable-line
                role="presentation"
                onKeyDown={(event) => this.onRatingKeyPress(event, onClick, toggle, section)}
                onMouseEnter={(event) => this.onRatingHover(event, onHover, interactive, section)}
                onMouseLeave={(event) => this.clearHoverState(event, onHover)}
                onClick={() => this.onRatingClick(onClick, toggle, section)}
                style={sectionStyle}
                className={ratingClasses}
              >
                { stars
                && <Image width="16" asset={asset} alt={`${sectionIndex + 1} star`} className="mw-icon-star-symbol" />}
              </span>
            );
          })
        }
      </div>
    );
  }
}

Rating.propTypes = {
  /** Additional classes to add to the container */
  className: PropTypes.string,
  /** Turns the meter to a changeable input as opposed to static */
  interactive: PropTypes.bool,
  /** Function for when the input is clicked. Returns clicked rating */
  onClick: PropTypes.func,
  /** Function for when the input is hovered
   * - returns value of hovered rating (1 - size) */
  onHover: PropTypes.func,
  /** Use brand color for fill */
  orange: PropTypes.bool,
  /** Additional classes to add to the individual meter sections (does not apply to static) */
  sectionClassName: PropTypes.string,
  /** Flag for keeping stars filled when clicking */
  stayFilled: PropTypes.bool,
  /** Display tooltip using title attribute on hover of rating component */
  titleEnabled: PropTypes.bool,
  /** Allow toggling selections off */
  toggle: PropTypes.bool,
  /** Rating type. Options: 'stars', 'circle', 'meter', 'dash' */
  type: PropTypes.oneOf(['stars', 'circle', 'meter', 'dash']),
  /** How much of the rating is filled */
  value: PropTypes.number
};

Rating.defaultProps = {
  className: null,
  interactive: false,
  onClick: null,
  onHover: null,
  orange: false,
  sectionClassName: null,
  stayFilled: false,
  titleEnabled: false,
  toggle: false,
  type: 'stars',
  value: null
};

export default Rating;
