import { useState } from 'react';

const useImageOverlay = (data, selectedPhotoID, isLoopy, isQuestionsAndAnswerPhotos, question) => {
  const values = [];
  let imageNumber = 0;
  let map;
  map = {};

  if (isQuestionsAndAnswerPhotos) {
    data?.forEach((photo) => {
      const review = {
        Question: question,
        ReviewText: photo.Caption,
        Photos: data
      };
      imageNumber += 1;
      map[photo.Id] = { value: { review, photo, imageNumber } };
      values.push(map[photo.Id]);
    }, {});
  } else {
    const { Results } = data;
    map = Results?.reduce((acc, review) => {
      review?.Photos?.forEach((photo) => {
        imageNumber += 1;
        acc[photo.Id] = { value: { review, photo, imageNumber } };
        values.push(acc[photo.Id]);
      });
      return acc;
    }, {});
  }

  const tail = values.reduce((acc, node) => {
    const currentNode = node;
    currentNode.previous = acc;
    return currentNode;
  }, null);

  const head = values.reduceRight((acc, node) => {
    const currentNode = node;
    currentNode.next = acc;
    return currentNode;
  }, null);

  if (isLoopy === true) {
    tail.next = head;
    head.previous = tail;
  }

  const [selected, setSelected] = useState(map[selectedPhotoID]);

  const selectImageByID = (id) => {
    if (map[id]) {
      setSelected(map[id]);
    }
  };
  const selectNextImage = () => {
    if (selected?.next) {
      setSelected(selected.next);
    }
  };
  const selectPreviousImage = () => {
    if (selected?.previous) {
      setSelected(selected.previous);
    }
  };

  return {
    hasNextImage: !!selected?.next,
    hasPreviousImage: !!selected?.previous,
    selected: selected?.value,
    selectImageByID,
    selectNextImage,
    selectPreviousImage,
    totalImages: values?.length,
  };
};

export { useImageOverlay };
